import React, { useEffect } from "react"
import customerservice from "../assets/imgs/marketing/customerservice.png"
import market from "../assets/imgs/marketing/market.png"
import internetofthings from "../assets/imgs/marketing/internetofthings.png"
import sales from "../assets/imgs/marketing/sales.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { Link } from "react-router-dom"

const MarketingServices = () => {
    useEffect(() => {
        window.scroll(0,0)
    }, [])

    return (
        <>
            <main id="main">
                <section class="breadcrumbs-inner" style={{background:`linear-gradient(rgba(6, 35, 65, 0.836), rgba(6, 37, 68, 0.856)),url(${market})`}}>
                    <div class="container">
                        <h2 style={{textAlign: "center"}} data-aos="fade-right">Marketing Services</h2>
                    </div>
                </section>
                <section id="service-about us" class="service-about">
                    <div class="container">
                        <div class="section-title" data-aos="fade-up">
                            <p>Financial marketing helps you with providing all the supports and finding best marketing strategies, We have a team of skilled marketers who will work on your behalf to find out what works best for your market and create the perfect strategy for you</p>
                        </div>
                        <div class="row content" data-aos="fade-right">
                            <div class="col-lg-6">
                                <p>
                                    We have simplified the whole process, </p>
                                <ul>
                                    <li className="ps-0"><FontAwesomeIcon className="pe-2" fontSize={12} style={{paddingBottom: "1px"}} icon={faChevronRight} /> We analyse and identify appropriate strategies you need </li>
                                    <li className="ps-0"><FontAwesomeIcon className="pe-2" fontSize={12} style={{paddingBottom: "1px"}} icon={faChevronRight} /> Conclude the operation by executing and implementing strategies </li>
                                    <li className="ps-0"><FontAwesomeIcon className="pe-2" fontSize={12} style={{paddingBottom: "1px"}} icon={faChevronRight} /> And help you with reach your target audience.</li>
                                </ul>
                            </div>
                            <div class="col-lg-6 pt-4 pt-lg-0" data-aos="fade-left">
                                <p class="d-none d-md-block">
                                    The marketing strategy of a business is based on the core principles and how these principles are to be implementing into practice.
                                    The hardest step to take when it comes to marketing is taking the first step. Many companies choose not to start marketing right away because they are unsure of what steps to take. We help you with that.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="cta" class="cta">
                    <div class="container" data-aos="fade-right">
                        <div class="text-center">
                            <h3>CONSULT OUR EXPERTS</h3>
                            <p>When time is of the essence, you can find all the services you need in one place. All you need to do is pick what you want, fill in some details and submit your application.</p>
                            <Link to={"#"} onClick={() => { window.open("tel:+97144358112", "_blank") }}  className="cta-btn">
                                Book an Appointment
                            </Link>
                        </div>
                    </div>
                </section>
                <section id="service-about" class="service-about" data-aos="fade-up">
                    <div class="container">
                        <div class="section-title">
                            <h2>Services We Provide</h2>
                            <p>Fintrek Marketing is about creating and implementing marketing strategies centred around the core of your business. We also provide support and assist you in achieving your business goals and objectives</p>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                                <Link to={"#"} onClick={() => { window.open("tel:+97144358112", "_blank") }} >
                                    <div class="icon-box">
                                        <div class="icon"><i><img src={internetofthings} alt="" /></i></div>
                                        <h4>Online Marketing</h4>
                                        <p>Reach your target customers and drive results quickly with us.</p>
                                    </div>
                                </Link>
                            </div>
                            <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                                <Link to={"#"} onClick={() => { window.open("tel:+97144358112", "_blank") }} >
                                    <div class="icon-box">
                                        <div class="icon"><i><img src={sales} alt="sales  team image of fintrek amrketing" /></i></div>
                                        <h4>Direct Sales</h4>
                                        <p>We are ready to start business development and sales activities.</p>
                                    </div>
                                </Link>
                            </div>
                            <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
                                <Link to={"#"} onClick={() => { window.open("tel:+97144358112", "_blank") }} >
                                    <div class="icon-box">
                                        <div class="icon"><i><img src={customerservice} alt="" /></i></div>
                                        <h4>Telesales</h4>
                                        <p>We provide contact center suitable for your business goals. </p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default MarketingServices