import React, { useEffect } from "react"
import sectionImg from "../assets/imgs/team/teamsHeader.jpg"

const Team = () => {
    useEffect(() => {
        window.scroll(0,0)
    }, [])

    return (
        <>
            <main id="main">


                <section class="breadcrumbs-inner" style={{background: `linear-gradient(rgba(6, 35, 65, 0.836), rgba(6, 37, 68, 0.856)),url(${sectionImg}) center`, backgroundRepeat: "no-repeat", backgroundSize: "cover"}} data-aos="zoom-out">
                    <div class="container">
                        <h2 style={{textAlign:" center"}} data-aos="fade-right">Our Team</h2>
                    </div>
                </section>

                <section id="team" class="team">
                    <div class="container" data-aos="fade-up">
                        <div style={{paddingTop:"30px"}} class="section-title">
                            <p class="col-lg-10 mx-auto">
                                Finterk marketing runs along with the team of talented and experienced sales 
                                executives who are having diverse knowledge of the financial sector, and offering 
                                a perfect consultancy regarding loan facilities in the UAE.
                            </p>
                        </div>
                    </div>
                </section>
                
                {/* <section class="team mx-auto" style={{background:" #0f2b52", maxWidth: "1500px", padding: "0 45px"}}>
                    <div class="section-title" data-aos="fade-up" data-aos-delay="100">
                        <h1 style={{color:" #fff", paddingTop:" 25px;"}}>Management Team</h1>
                    </div>

                    <div class="row">

                        <div class="col-xl-4 col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
                            <div class="member">
                                <img src="assets/img/team/Untitled-1.png" class="img-fluid" alt="Fintrek markteting team pics" />
                                <div class="member-details" style={{background:" #0f2b52", paddingBottom:" 10px", paddingTop: "10px;"}}>

                                    <h4>Bushra Yousuf</h4>
                                    <p><span>Head of Operations - Banking & Sales</span></p>


                                </div>
                            </div>

                        </div>

                        <div class="col-xl-4 col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
                            <div class="member">
                                <img src="assets/img/team/Untitled-2.png" class="img-fluid" alt="" />
                                <div class="member-details" style={{background:" #0f2b52", paddingBottom: "10px", paddingTop:" 10px;"}}>

                                    <h4>Hassan Shah </h4>
                                    <p><span>Head of Business - Abu Dhabi</span></p>


                                </div>
                            </div>
                        </div>

                        <div class="col-xl-4 col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
                            <div class="member">
                                <img src="assets/img/team/Untitled-4.png" class="img-fluid" alt="" />

                                <div class="member-details" style={{background:" #0f2b52", paddingBottom:" 10p", paddingTop: "10px;"}}>

                                    <h4>Muhammad Bahzad</h4>
                                    <p><span>Sales Manager - Dubai</span></p>


                                </div>
                            </div>
                        </div>
                    </div>

                </section> */}

            </main>
        </>
    )
}

export default Team