import React, { useState, useEffect } from "react"
import logoImage from "../assets/imgs/header_logo.svg"
import { Link, NavLink } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"

const Header = () => {
    const [scrollPosition, setScrollPosition] = useState(false)
    const [navCheck, setNavCheck] = useState(false)
    const handleScroll = () => {
        if(window.pageYOffset > 0) setScrollPosition(true)
        else setScrollPosition(false)
    }
  
    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true })
  
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return (
        <div className={`header ${scrollPosition && "header-scrolled"} fixed-top d-flex align-items-center`}>
            <div className="logoBack"></div>
            <div className="container d-flex align-items-center">
                <Link className="logo me-auto pb-0" to={"/"}>
                    <img src={logoImage} href="" />
                </Link>

                <nav id="navbar" class="navbar order-last order-lg-0">
                    <ul>
                        <li>
                            <NavLink to={"/"}>
                                Home
                            </NavLink>
                        </li>
                        <li class="dropdown">
                            <NavLink to={"/about"}>
                                <span>About</span> <FontAwesomeIcon className="ps-1" fontSize={10} icon={faChevronDown} />
                            </NavLink>
                            <ul>
                                <li>
                                    <NavLink to={"/about"}>
                                        About Us
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"/founder"}>
                                        Meet our Founder
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"/team"}>
                                        Our Team
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"/news-updates"}>
                                        News & Updates
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"/awards"}>
                                        Awards
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li class="dropdown">
                            <NavLink to="/our-services">
                                <span>Services</span> <FontAwesomeIcon className="ps-1" fontSize={10} icon={faChevronDown} />
                            </NavLink>
                            <ul>
                                <li>
                                    <NavLink to={"/our-services"}>
                                        Our Services
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"/consumer-services"}>
                                        Consumer Service
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"/corporate-financing"}>
                                        Corporate Financing
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"/marketing-services"}>
                                        Marketing Services
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"/debt-collection-recovery"}>
                                        Debt Collection & Recovery
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"https://fintrek.me"} target="_blank">
                                        Risk Advisory Services
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <NavLink to={"/careers"}>
                                Careers
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={"/contact"}>
                                Contact
                            </NavLink>
                        </li>
                        <a className="p-0" href="tel:+97144358112">
                            <div className="get-started-btn scrollto pointer">
                                Consult Us
                            </div>
                        </a>
                    </ul>
                    <nav className="menu--right" role="navigation">
                        <div className={`menuToggle ${scrollPosition && "header-scrolled"}`} onClick={() => {setNavCheck(!navCheck)}}>
                            <input type="checkbox" checked={navCheck}/>
                            <span></span>
                            <span></span>
                            <span></span>
                            <ul className="menuItem">
                            <li>
                            <NavLink to={"/"} onClick={() => {setNavCheck(!navCheck)}}>
                                Home
                            </NavLink>
                        </li>
                        <li>
                                    <NavLink to={"/about"} onClick={() => {setNavCheck(!navCheck)}}>
                                        About Us
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"/founder"} onClick={() => {setNavCheck(!navCheck)}}>
                                        Meet our Founder
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"/team"} onClick={() => {setNavCheck(!navCheck)}}>
                                        Our Team
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"/news-updates"} onClick={() => {setNavCheck(!navCheck)}}>
                                        News & Updates
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"/awards"} onClick={() => {setNavCheck(!navCheck)}}>
                                        Awards
                                    </NavLink>
                                </li>
                        <li>
                            <NavLink to={"/our-services"} onClick={() => {setNavCheck(!navCheck)}}>
                                Our Services
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={"/consumer-services"} onClick={() => {setNavCheck(!navCheck)}}>
                                Consumer Service
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={"/corporate-financing"} onClick={() => {setNavCheck(!navCheck)}}>
                                Corporate Financing
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={"/marketing-services"} onClick={() => {setNavCheck(!navCheck)}}>
                                Marketing Services
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={"/debt-collection-recovery"} onClick={() => {setNavCheck(!navCheck)}}>
                                Debt Collection & Recovery
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={"/careers"} onClick={() => {setNavCheck(!navCheck)}}>
                                Careers
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={"/contact"} onClick={() => {setNavCheck(!navCheck)}}>
                                Contact
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={"/contact"} onClick={() => {setNavCheck(!navCheck)}}>
                                Consult Us
                            </NavLink>
                        </li>
                            </ul>
                        </div>
                    </nav>

                </nav>
            </div>
        </div>
    )
}

export default Header