import React, { useEffect } from "react"
import ServiceHeader from "../assets/imgs/Service/ServiceHeader.jpg"
import Servicecustomer from "../assets/imgs/Service/Servicecustomer.png"
import corporateFin from "../assets/imgs/Service/corporateFin.jpg"
import Digitalmarketing from "../assets/imgs/Service/Digitalmarketing.jpg"
import Debtcollection from "../assets/imgs/Service/Debtcollection.jpg"
import riskAdvisory from "../assets/imgs/Service/RiskManagement.jpg"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"


const OurServices = () => {
    useEffect(() => {
        window.scroll(0,0)
    }, [])

    return (
        <>
            <main id="main">
                <section id="breadcrumbs" class="d-flex align-items-center" style={{background: `url(${ServiceHeader}) center`, backgroundSize: "cover", backgroundRepeat: "no-repeat"}}>
                    <div class="container position-relative" data-aos="fade-up" data-aos-delay="500">
                        <h1 class="pt-3">Our Services</h1>
                    </div>
                </section>

                <section class="work-process">
                    <div class="container">
                        <div class="section-title pt-3 pt-lg-5" data-aos="fade-up">
                            <p> Fintrek Marketing is a premier financial and marketing consultant. Dedicated towards customer services in various channels of financial services.</p>
                        </div>
                        <div class="row content">
                            <h3>Consumer Services</h3>
                            <div class="col-md-7 pt-3 order-2 order-md-1" data-aos="fade-right">
                                <p class="pt-3">
                                    We help clients accomplish financial objectives by assessing financial situation, developing and presenting financial
                                    strategies and plans, monitoring changes in financial status and life circumstance.
                                </p>
                                <p class="d-none d-md-block">
                                    We are skilled and knowledged in consumer finance. We provide solutions by offering
                                    advice on financial products that cover the different requirements of client.
                                </p>
                                <div class="learn">
                                    <Link to={"/consumer-services"} className="about1-btn">
                                        Learn More
                                        <FontAwesomeIcon className="ps-2" fontSize={12} icon={faChevronRight} /> 
                                    </Link>
                                </div>
                            </div>
                            <div class="col-md-5 pt-2 order-1 order-md-2" data-aos="fade-left">
                                <img src={Servicecustomer} class="img-fluid imgBordered" alt="Customer services pic in fintrekmarketing" style={{borderRadius: "20px;" }}/>
                            </div>
                        </div>

                        <div class="row content">
                            <h3 class="p-lg-2">Corporate Financing</h3>
                            <div class="col-md-5 pt-3" data-aos="fade-right">
                                <img src={corporateFin} class="img-fluid imgBordered" alt="" style={{borderRadius: "20px;"}} />
                            </div>
                            <div class="col-md-7  p-sm-3" data-aos="fade-left">

                                <p class="pt-3">We assist and facilitate financial products to companies for the growth of their business, perfect financial
                                    strategies are designed for company's success. If the need is for financial support, we assist them. </p>
                                <p class="d-none d-md-block">We provide best solution to our corporate customer. These financial solutions organize them to fulfil their target
                                    we help them to obtain.
                                </p>
                                <div class="learn">
                                    <Link to={"/corporate-financing"} className="about1-btn">
                                        Learn More
                                        <FontAwesomeIcon className="ps-2" fontSize={12} icon={faChevronRight} /> 
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div class="row content">
                            <h3>Marketing Consultancy</h3>
                            <div class="col-md-5 pt-3 order-1 order-md-2" data-aos="fade-left">
                                <img src={Digitalmarketing} class="img-fluid imgBordered" alt="" style={{ borderRadius: "20px"}}/>
                            </div>
                            <div class="col-md-7 pt-3 order-2 order-md-1" data-aos="fade-right">
                                <p>
                                    We work with companies to create and implement marketing strategies. These strategies are centered around the core
                                    of the business. Providing support in launching new products, and finding the best marketing
                                    consultancy and implementation.
                                </p>
                                <p class="d-none d-md-block">
                                    Fintrek Marketing is one stop destination, our expertise provide detailed marketing plan and determine the marketing message. We follow up by identifying the appropriate marketing mix to get the message
                                    out to and conclude the operation by executing and implementing marketing strategy.
                                </p>
                                <div class="learn">
                                    <Link to={"/marketing-services"} className="about1-btn">
                                        Learn More
                                        <FontAwesomeIcon className="ps-2" fontSize={12} icon={faChevronRight} /> 
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div class="row content">
                            <h3 class="p-lg-2">Debt Collection and Recovery</h3>
                            <div class="col-md-5 pt-3" data-aos="fade-right">
                                <img src={Debtcollection} class="img-fluid imgBordered" alt="" style={{borderRadius: "20px;" }}/>
                            </div>
                            <div class="col-md-7  pt-3 pt-lg-0" data-aos="fade-left">

                                <p>We will also help you to recover your unpaid debts, with a qualified team.
                                    We ensure that our clients’ needs are always met by providing fast, efficient and responsible service.</p>
                                <p>Our team contacts the debtor either by phone or mail with an overdue balance and often advises them of potential consequences if they fail to pay their debts.</p>
                                <p class="d-none d-md-block"> We have worked with number of clients to recover outstanding debts of all sorts, including business debts, payday loans and personal loans.</p>
                                <div class="learn">
                                    <Link to={"/debt-collection-recovery"} className="about1-btn">
                                        Learn More
                                        <FontAwesomeIcon className="ps-2" fontSize={12} icon={faChevronRight} /> 
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div class="row content">
                            <h3>Comprehensive Risk Advisory Services</h3>
                            <div class="col-md-5 pt-3 order-1 order-md-2" data-aos="fade-left">
                                <img src={riskAdvisory} class="img-fluid imgBordered" alt="" style={{ borderRadius: "20px"}}/>
                            </div>
                            <div class="col-md-7 pt-3 order-2 order-md-1" data-aos="fade-right">
                                <p>
                                    Leverage our Risk Advisory Expertise to safeguard your business's future. Our consultants, trained by renowned global firms, 
                                    bring in-depth knowledge across various risk domains including operational, cyber security, and technology risks.
                                    We assess your risk exposure, develop mitigation strategies, and ensure your resilience against threats.
                                </p>
                                <p class="d-none d-md-block">
                                    Our services focus on maximizing your organizational agility and maintaining continuity in today’s dynamic business environment. 
                                    Partner with us for strategic risk management solutions that are as robust as they are intelligent.
                                </p>
                                <div class="learn">
                                    <Link to={"https://fintrek.me"} target="_blank" className="about1-btn">
                                        Learn More
                                        <FontAwesomeIcon className="ps-2" fontSize={12} icon={faChevronRight} /> 
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



            </main>
        </>
    )
}

export default OurServices