import React from 'react'
import Lottie from 'react-lottie'
import * as animationData from "../assets/lottie/success.json"

const SuccessLottie = () => {
    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }

    return (
        <div>
            <Lottie
                options={defaultOptions}
                height={250}
                width={250}
            />
        </div>
    )
}

export default SuccessLottie