import React, { useEffect, useState } from "react"
import headerImage from "../assets/imgs/awards/websiteHeader.jpg"
import { AnimatePresence, motion } from "framer-motion";


import img_4271_S from "../assets/imgs/awards/mini/IMG_4271.jpg"
import img_4272_S from "../assets/imgs/awards/mini/IMG_4272.jpg"
import img_4274_S from "../assets/imgs/awards/mini/IMG_4274.jpg"
import img_4276_S from "../assets/imgs/awards/mini/IMG_4276.jpg"
import img_4281_S from "../assets/imgs/awards/mini/IMG_4281.jpg"
import img_4282_S from "../assets/imgs/awards/mini/IMG_4282.jpg"
import img_4290_S from "../assets/imgs/awards/mini/IMG_4290.jpg"
import img_4285_S from "../assets/imgs/awards/mini/IMG_4285.jpg"
import img_4286_S from "../assets/imgs/awards/mini/IMG_4286.jpg"
import img_4299_S from "../assets/imgs/awards/mini/IMG_4299.jpg"
import img_4292_S from "../assets/imgs/awards/mini/IMG_4292.jpg"

import img_4271 from "../assets/imgs/awards/IMG_4271.jpg"
import img_4272 from "../assets/imgs/awards/IMG_4272.jpg"
import img_4274 from "../assets/imgs/awards/IMG_4274.jpg"
import img_4276 from "../assets/imgs/awards/IMG_4276.jpg"
import img_4281 from "../assets/imgs/awards/IMG_4281.jpg"
import img_4282 from "../assets/imgs/awards/IMG_4282.jpg"
import img_4290 from "../assets/imgs/awards/IMG_4290.jpg"
import img_4285 from "../assets/imgs/awards/IMG_4285.jpg"
import img_4286 from "../assets/imgs/awards/IMG_4286.jpg"
import img_4299 from "../assets/imgs/awards/IMG_4299.jpg"
import img_4292 from "../assets/imgs/awards/IMG_4292.jpg"
import { Lightbox } from "react-modal-image";

const awardList = [
    {
        smallImg: img_4271_S,
        img: img_4271,
        header: "Highest Revenue Runner's up",
        year: "2018",
        subText: "Awarded by Emirates NBD"
    },       
    {
        smallImg: img_4272_S,
        img: img_4272,
        header: "Best Portfolio - Credit Cards",
        year: "2018",
        subText: "Awarded by Emirates NBD"
    },               
    {
        smallImg: img_4274_S,
        img: img_4274,
        header: "Top performer CASA",
        year: "2018",
        subText: "Awarded by Emirates NBD"
    },               
    {
        smallImg: img_4276_S,
        img: img_4276,
        header: "Highest Account Opened",
        year: "2017",
        subText: "Awarded by Emirates NBD"
    },               
    {
        smallImg: img_4281_S,
        img: img_4281,
        header: "Top Sales Manager Business Development",
        year: "2019",
        subText: "Awarded by Emirates NBD"
    },               
    {
        smallImg: img_4282_S,
        img: img_4282,
        header: "Best Channel Award Governance & Operational Control",
        year: "2019",
        subText: "Awarded by Emirates NBD"
    },               
    {
        smallImg: img_4290_S,
        img: img_4290,
        header: "Top performer CASA",
        year: "2018",
        subText: "Awarded by Emirates NBD"
    },               
    {
        smallImg: img_4285_S,
        img: img_4285,
        header: "Highest Revenue Runner's up",
        year: "2019",
        subText: "Awarded by Emirates NBD"
    },               
    {
        smallImg: img_4286_S,
        img: img_4286,
        header: "Highest Volume in Payroll referral.",
        year: "2016",
        subText: "Awarded by Emirates NBD"
    },               
    {
        smallImg: img_4299_S,
        img: img_4299,
        header: "Lowest Delinquency Cards",
        year: "2016",
        subText: "Awarded by Emirates NBD"
    },               
    {
        smallImg: img_4292_S,
        img: img_4292,
        header: "Gems Awareness Day 2021",
        year: "2021",
        subText: "Awarded by Gems Education"
    },       
]

const Awards = () => {

    const [filtered, setFiltered] = useState(awardList)
    const [year, setYear] = useState("all")
    const [imageModal, setImageModal] = useState(false)
    const [imgUrl, setImgUrl] = useState("")

    const handleFilter = (year) => {
        setYear(year)
        if(year == "all") {
            setFiltered(awardList)
            return
        }
        setFiltered(awardList.filter((award) => award.year === year))
    }

    useEffect(() => {
        window.scroll(0,0)
    }, [])

    return (
        <>
            <section class="breadcrumbs-inner" style={{background: `linear-gradient(rgba(6, 35, 65, 0.836), rgba(6, 37, 68, 0.856)), url(${headerImage}) center`}} data-aos="zoom-out">
                <div class="container">
                    <h2 style={{ textAlign: "center"}} data-aos="fade-right" >Awards and Recognition</h2>
                </div>
            </section>

            <section id="portfolio" class="portfolio" style={{paddingTop: "30px", paddingBottom: "30px"}}>
                <div class="container" data-aos="fade-up">
                    <div class="row" data-aos="fade-up" data-aos-delay="100">
                        <div class="col-lg-12 d-flex justify-content-center">
                            <ul id="portfolio-flters">
                                <li className={year === "all" && "filter-active"} onClick={() => handleFilter("all")}>All</li>
                                <li className={year === "2023" && "filter-active"} onClick={() => handleFilter("2023")}>2023</li>
                                <li className={year === "2022" && "filter-active"} onClick={() => handleFilter("2022")}>2022</li>
                                <li className={year === "2021" && "filter-active"} onClick={() => handleFilter("2021")}>2021</li>
                                <li className={year === "2020" && "filter-active"} onClick={() => handleFilter("2020")}>2020</li>
                                <li className={year === "2019" && "filter-active"} onClick={() => handleFilter("2019")}>2019</li>
                                <li className={year === "2018" && "filter-active"} onClick={() => handleFilter("2018")}>2018</li>
                                <li className={year === "2017" && "filter-active"} onClick={() => handleFilter("2017")}>2017</li>
                                <li className={year === "2016" && "filter-active"} onClick={() => handleFilter("2016")}>2016</li>
                            </ul>
                        </div>
                    </div>
                    <motion.div
                        className="col-lg-8 mx-auto portfolio-container"
                        data-aos="fade-up"
                        data-aos-delay="200"
                        initial={{ opacity: 0 }} // Initial opacity
                        animate={{ opacity: 1 }} // Animates opacity when filtering
                    >
                        <AnimatePresence>
                        {
                            filtered.map((item) => {
                                return (
                                    <motion.div
                                        key={item.img}
                                        layout
                                        animate={{ opacity: 1 }}
                                        initial={{ opacity: 0 }}
                                        exit={{ opacity: 0 }}
                                    >
                                        <div class="col-lg-12 portfolio-item filter-eight">
                                            <div class="member d-flex align-items-start">
                                                <div class="pic toggle" onClick={() => { setImgUrl(item.img); setImageModal(true); }}>
                                                    <img src={item.smallImg} class="img-fluid" alt="" />
                                                </div>
                                                <div class="member-info">
                                                    <h4>{item.header}</h4>
                                                    <span>{item.year}</span>
                                                    <p>{item.subText}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </motion.div>
                                )
                            })
                        }
                        </AnimatePresence>
                    </motion.div>

                    {
                        imageModal &&
                            <Lightbox
                                medium={imgUrl}
                                alt=""
                                onClose={() => setImageModal(false)}
                            />
                    }
                </div>
            </section>
        </>
    )
}

export default Awards