import React, { useEffect } from "react"
import HeaderConsumer from "../assets/imgs/Consumerservices/HeaderConsumer.png"


const PrivacyPolicy = () => {
    useEffect(() => {
        window.scroll(0,0)
    }, [])

    return (
        <>
            <main id="main">
                <section class="breadcrumbs-inner" style={{background: `linear-gradient(rgba(6, 35, 65, 0.836), rgba(6, 37, 68, 0.856)), url(${HeaderConsumer}) center`}}>
                    <div class="container">
                        <h1 style={{textAlign:" center;" }}data-aos="fade-right"><center>Privacy Policy</center></h1>
                    </div>
                </section>


                <section id="service-about us" class="service-about">
                    <div class="container">
                        <h1>Privacy Statement</h1>
                        <p>This Privacy Statement describes how and why Fintrek Marketing ("Fintrek" or “we” or “us”) collects and uses personal data (i.e. data relating to an identified or identifiable individual) in the course of its business. It applies to personal data provided directly to us by the individuals concerned and to personal data provided to us by companies and other organisations.</p>
                        <p>We are committed to the protection of personal data and to fair and transparent processing. If you have any questions about this Privacy Statement, you can contact via email at <a href="mailto:about@fintrekmarketing.com">about@fintrekmarketing.com</a>. To find out more about how and why we process personal data, please refer to the relevant section of this Privacy Statement.</p>

                        <h2>Security of Personal Data</h2>
                        <p>We have policies, procedures and training in place in respect of data protection, confidentiality, and information security. We regularly review such measures with the objective of ensuring their continuing effectiveness. The Privacy Statement was last updated on 01 January 2021 and is reviewed at least annually.</p>

                        <h2>International Transfers of Personal Data</h2>
                        <p>In the course of running our business and providing services to clients we may transfer personal data to third parties located in other countries, including countries outside the GCC. Where we transfer personal data to a country not determined by the GCC to provide an adequate level of protection for personal data, we will only do so under a form of agreement approved by an accepted Standard Contractual Clause in line with the GCC regulations.</p>

                        <h2>Provision of Personal Data to Third Parties</h2>
                        <p>We will only share personal data with third parties where we are legally permitted to do so. We do not provide information to third parties for their own marketing purposes and we do not undertake mailings for third parties. Where we transfer personal data to third parties, we will put in place appropriate contractual arrangements and seek to ensure that there are appropriate technical and organisational measures in place to protect personal data.</p>
                        <ul>
                            <li><strong>Business partners</strong> – we may share personal data with our business partners as required for the provision of services to our clients and/or for administrative purposes.</li>
                            <li><strong>Third parties involved in the performance of services</strong> – we may also share personal data with third party organisations who assist us in providing services to clients or are otherwise involved in the services we provide to clients.</li>
                            <li><strong>Third parties who provide IT services, data processing or functionality</strong> – like many professional service providers, we use third party providers to support our business and the provision of services to our clients, such as cloud-based software providers, web hosting/management providers, data analysis providers, and data back-up and security/storage providers.</li>
                            <li><strong>Auditors and advisers</strong> – we may transfer personal data to our auditors and advisers as required by law or as reasonably required in the management of our business.</li>
                            <li><strong>Third parties where required by applicable law and regulation</strong> – we may be requested or compelled to disclose personal data to third parties such as regulators and law enforcement agencies.</li>
                        </ul>

                        <h2>Your Rights</h2>
                        <p>You have rights in relation to any of your personal data held by us as a data controller. Should you wish to exercise your rights, please contact our Data Protection Officer via email at <a href="mailto:about@fintrekmarketing.com">about@fintrekmarketing.com</a>. Please mark the subject with ‘Enquiry’. We will endeavour to respond to any request promptly and within any legally required time limit. You also have a right to update your personal data that we hold. Where we process your personal data based on your consent, you have a right to withdraw consent at any time. Should you wish to do so, please contact our Data Protection Officer via the email stated above.</p>
                        <p>In addition to the rights above, you may also have other rights in relation to personal data, including a right to erasure/deletion, the right to data portability, and the right to restrict and/or object to our processing of personal data.</p>

                        <h2>Complaints</h2>
                        <p>Should you wish to complain about our use of your personal data, please contact our Data Protection Officer via email at <a href="mailto:about@fintrekmarketing.com">about@fintrekmarketing.com</a>. Please mark the email subject as 'Complaints’. We will investigate all complaints received and will endeavour to respond to complaints promptly.</p>

                        <h2>Data Retention</h2>
                        <p>Generally, we avoid requesting any personal data as part of our services or operations; however, if we request such data then we will only keep personal data for as long as necessary for the purposes for which it was collected, or as required by applicable law or regulation. Unless there are any overriding legal, regulatory or contractual requirements, we will retain records of services provided (which may include personal data) in accordance with our document retention policy.</p>

                        <h2>Clients (and individuals connected with our clients)</h2>
                        <p>We aim to collect personal data only to the extent necessary for us to provide our services to our clients and for other agreed purposes. Where personal data is required for us to perform services for our clients, we request that our clients provide all necessary information to relevant individuals (known as “data subjects”) about our use of personal data. Our clients may therefore refer data subjects to this Privacy Notice. We generally collect personal data directly from our clients or from third parties acting on their instructions.</p>

                        <h2>Website Visitors</h2>
                        <p>We do not require registration in order for you to access <a href="https://www.fintrekmarketing.com" target="_blank">www.fintrekmarketing.com</a>, but if you participate in any of the activities or services offered by the website, we will collect the personal data that we need in order to provide you with those services, such as your name, job title, email address, employer's name, and telephone number. We may also collect technical information such as your IP (Internet Protocol) address, details of the pages you visit, and which browser you used to view our website.</p>
                        <p>This website collects standard internet log and technical data to measure and improve the effectiveness of this website, to help diagnose problems with our server, to administer this website, and to see where website traffic is coming from. We may also collect other information via <a href="https://www.fintrekmarketing.com" target="_blank">www.fintrekmarketing.com</a>, such as website usage activity and preferences.</p>
                    </div>
                </section>
            </main>
        </>
    )
}

export default PrivacyPolicy