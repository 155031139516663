import React, { useEffect } from "react"
import headerdebt from "../assets/imgs/Debtcollection/headerdebt.png"
import datacollection from "../assets/imgs/Debtcollection/datacollection.png"
import debt from "../assets/imgs/Debtcollection/debt.png"
import workschedule from "../assets/imgs/Debtcollection/workschedule.png"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"


const DebtCR = () => {
    useEffect(() => {
        window.scroll(0,0)
    }, [])

    return (
        <>
            <main id="main">
                <section class="breadcrumbs-inner" style={{background:`linear-gradient(rgba(6, 35, 65, 0.836), rgba(6, 37, 68, 0.856)),url(${headerdebt})`}}>
                    <div class="container">
                        <h2 style={{textAlign: "center;"}} data-aos="fade-right"><center>Debt Collection And Recovery</center></h2>
                    </div>
                </section>
                <section id="service-about us" class="service-about">
                    <div class="container">
                        <div class="section-title" data-aos="fade-up">
                            <p>Debt collections are an essential part of financial industry. We assure our clients get their money back. We have a separate team dedicated to rescheduling services, deal with debt collection, skip tracking and legal services.</p>
                        </div>
                        <div class="row content" data-aos="fade-right">
                            <div class="col-lg-6">
                                <ul>
                                    <li className="ps-0"><FontAwesomeIcon className="pe-2" fontSize={12} style={{paddingBottom: "1px"}} icon={faChevronRight} /> We ensure that client receives the due attention.</li>
                                    <li className="ps-0"><FontAwesomeIcon className="pe-2" fontSize={12} style={{paddingBottom: "1px"}} icon={faChevronRight} /> We care about their situation and help them comeback.</li>
                                    <li className="ps-0"><FontAwesomeIcon className="pe-2" fontSize={12} style={{paddingBottom: "1px"}} icon={faChevronRight} /> Consistently advice them of potential consequences.</li>
                                </ul>
                            </div>
                            <div class="col-lg-6 pt-4 pt-lg-0" data-aos="fade-left">
                                <p>
                                    Fintrek Marketing help you with the recoveries of unpaid debts by working with the clients. We have a good reputation among businesses as they work on their terms and provide good recovery rescheduling services to help clients come back on track.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="cta" class="cta">
                    <div class="container" data-aos="fade-right">
                        <div class="text-center">
                            <h3>CONSULT OUR EXPERTS</h3>
                            <p>When time is of the essence, you can find all the services you need in one place. All you need to do is pick what you want, fill in some details and submit your application.</p>
                            <Link to={"#"} onClick={() => { window.open("tel:+97144358112", "_blank") }}  className="cta-btn">
                                Book an Appointment
                            </Link>
                        </div>
                    </div>
                </section>
                <section id="service-about" class="service-about" data-aos="fade-up">
                    <div class="container">
                        <div class="section-title">
                            <h2>Services We Provide</h2>
                            <p>We ensure that our clients’ needs are always met by providing fast, efficient and responsible service.</p>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 col-md-6 d-flex align-items-stretch ">
                                <Link to={"#"} onClick={() => { window.open("tel:+97144358112", "_blank") }} >
                                    <div class="icon-box">
                                        <div class="icon"><i><img src={debt} /></i></div>
                                        <h4>Debt Collection</h4>
                                        <p>We contact the debtor and arrange repayment. <br /> <br /></p>
                                    </div>
                                </Link>
                            </div>
                            <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                                <Link to={"#"} onClick={() => { window.open("tel:+97144358112", "_blank") }} >
                                    <div class="icon-box">
                                        <div class="icon"><i><img src={datacollection}/></i></div>
                                        <h4>Recovery</h4>
                                        <p>We identify, track and resolve the money owed. <br/> <br/></p>
                                    </div>
                                </Link>
                            </div>
                            <div class="col-lg-4 col-md-6 d-flex align-items-stretch ">
                                <Link to={"#"} onClick={() => { window.open("tel:+97144358112", "_blank") }} >
                                    <div class="icon-box">
                                        <div class="icon"><i><img src={workschedule}/></i></div>
                                        <h4>Rescheduling</h4>
                                        <p>We provide you with simple reschedulment process to ease your debt burden.</p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default DebtCR