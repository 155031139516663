import React, { useEffect } from "react"
import slide1 from "../assets/imgs/founder/slide1.jpg"
import founder1 from "../assets/imgs/founder/founder1.jpg"


const Founder = () => {
    useEffect(() => {
        window.scroll(0,0)
    }, [])
    
    return (
        <>
            <main id="main">
                <section class="breadcrumbs-inner" style={{background: `linear-gradient(rgba(6, 35, 65, 0.836), rgba(6, 37, 68, 0.856)),url(${slide1})`}}  data-aos="zoom-out">
                    <div class="container">
                        <h2 style={{textAlign: "center;"}} data-aos="fade-right"><center>Meet Our Founder</center></h2>
                    </div>
                </section>

                <section id="founder-about" class="founder-about" style={{paddingTop:" 0;"}}>
                    <div class="container" data-aos="fade-up">

                        <div class="section-title mt-0">
                            <h2 style={{color: "#0f2b52;", margin: "0"}}> <b> Mohammad Aamir Yousuf, Managing Director</b></h2>    
                        </div>

                        <div class="row pt-4 pt-lg-0">

                            <div class="col-lg-4 order-lg-2 ">
                                <img src={founder1} class="img-fluid founder-img" alt="founder of fintrek marketing agency" />
                            </div>

                            <div class="col-lg-8 founder icon-boxes d-flex flex-column align-items-stretch justify-content-center p-4 oder-lg-1">
                                <div class="para">
                                    <p style={{color: "#fff", fontSize:" 18.5px", wordSpacing: "3px", fontWeight: "400", paddingTop:"10px"}}>Mr. Aamir has 25 years of experience of retail bank. He has worked on senior positions as top ranked individual of the bank.
                                        In his role as <b>Head of Sales - Asset Products,</b> he effectively set up direct sale agency (DSA) channel for Citibank- Pakistan in 1999. </p>
                                </div>
                                <div class="para">

                                    <p style={{color:" #fff", fontSize: "18.5px", wordSpacing: "3px", fontWeight:" 400" }}>Success chain continued as he was appointed as a youngest <b>Business Head for Auto and Personal loan</b> for Citibank -Bahrain and grow business by 40% within the next 2 years. Later he was promoted as <b>Head of Collection and Fraud</b> for Citibank- Bahrain and brought down impairment numbers to best ever in the history of Citibank Bahrain.</p>

                                </div>


                                <div class="para">
                                    <p style={{color:" #fff", fontsize:" 18.5px", wordSpacing:"3px", fontWeight:" 400"}}>Further to his success story, he was appointed as <b>Head of Sales - Unsecured Products</b> in Barclays Bank - UAE. He later took position of <b>Head of Recovery</b> in 2008 when bank needed his expertise of recovery at time of financial distress during 2008. Recovery numbers went sky high and it became the only revenue line for the bank in difficult position.</p>

                                </div>
                                <div class="para">
                                    <p style={{color:" #fff", fontSize: "18.5px", wordSpacing: "3px", fontweight:" 400",fontFamily: "Alegreya;"}}>In the year 2014 Mr.Aamir successfully set up Direct Sale Agency in UAE - Fintrek Marketing which is now one of the top DSA of UAE having 6 banks as channel partner contributing in the sale of Cards and loan. Under his leadership and diversified experience, the company is growing year by year and achieving new challenges and milestone. </p>

                                </div>

                            </div>

                        </div>
    </div>
                </section>

            </main>
        </>
    )
}

export default Founder