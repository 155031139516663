import React, { useRef, useState } from "react"
import homeVideo from "../assets/vids/video-home.mp4"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"
import Slider from "react-slick"
import CountUp from 'react-countup'
import { useIsVisible } from "react-is-visible"
import { Button, Form, InputGroup, Modal } from "react-bootstrap"
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import 'react-datepicker/dist/react-datepicker.css'
import { isEmpty } from "../config/funcs"

// font awesome icons
import { faStackOverflow } from "@fortawesome/free-brands-svg-icons"
import { faChevronRight, faChartColumn, faSuitcase, faCreditCard, faPhoneVolume, faRectangleList, faFileMedical } from "@fortawesome/free-solid-svg-icons"
import { faCalendarDays } from "@fortawesome/free-regular-svg-icons"

// images
import homeBoxBack from "../assets/imgs/home-boxBack.png"
import homeCount from "../assets/imgs/homeClients.jpg"
import homeCareer from "../assets/imgs/homeCareer.jpg"
import logoWizz from "../assets/imgs/logoWizhull.png"

// logos
import eisLogo from "../assets/imgs/logos/eis.svg"
import nbdLogo from "../assets/imgs/logos/enbd.svg"
import mashreqLogo from "../assets/imgs/logos/mashreq.svg"
import gemsLogo from "../assets/imgs/logos/gems.png"
import sibLogo from "../assets/imgs/logos/sib.svg"
import cbdLogo from "../assets/imgs/logos/cbd.svg"
import dibLogo from "../assets/imgs/logos/dib.svg"

// icons
import creditIcon1 from "../assets/imgs/home/creditCard1.svg"
import accountIcon from "../assets/imgs/home/accountOpening.svg"
import personalLoanIcon from "../assets/imgs/home/personalLoan.svg"
import autoLoanIcon from "../assets/imgs/home/autoLoan.svg"
import mortgageLoanIcon from "../assets/imgs/home/mortgageLoan.svg"
import SMELoanIcon from "../assets/imgs/home/SMELoan.svg"

import smileIcon from "../assets/imgs/smile.png"
import awardIcon from "../assets/imgs/award.png"
import paperIcon from "../assets/imgs/paperStack.png"
import teamIcon from "../assets/imgs/team.png"

// dataset
import { nationalities, uaeMainCities } from "../config/dateset"

// validation
import { homeValidation } from "../component/Validation"

// actions
import { applyService } from "../actions/common"
import { Oval } from "react-loader-spinner"
import SuccessLottie from "../component/successLottie"
import { useEffect } from "react"

const initialValue = {
    name: '',
    dob: '',
    gender: '',
    email: '',
    phone: '',
    nationality: '',
    emirates: '',
    company: '',
    salary: '',
    type: ''
}

const Home = () => {
    const nodeRef = useRef()
    const isVisible = useIsVisible(nodeRef)

    const [show, setShow] = useState(false)
    const [formValue, setFormValue] = useState(initialValue)
    const [validation, setValidation] = useState({})
    const [loader, setLoader] = useState(false)
    const [optText, setOptText] = useState("")
    const [optStatus, setOptStatus] = useState("")
    const [countdown, setCountdown] = useState(5)

    const { name, dob, gender, email, phone, nationality, emirates, company, salary, type } = formValue

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        autoplayspeed: 3000,
        slickPlay: true,
        dots: true
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormValue({
            ...formValue, [name]: value
        })
    }

    const handleSelectChange = (e, name) => {
        const { value } = e
        setFormValue({
            ...formValue, [name]: value
        })
    }

    const handleDateChange = (e) => {
        setFormValue({
            ...formValue, dob: e
        })
    }

    const handleGender = (data) => {
        setFormValue({
            ...formValue, "gender": data
        })
    }

    const handleSubmit = async() => {
        try {
            let formData = formValue
            let _error = homeValidation(formData)
            setValidation(_error)
            
            if(Object.keys(_error).length > 0 && !isEmpty(_error)) return
            
            setLoader(true)
            let { status, message, error } = await applyService(formData)
            if(status == "success") setOptText(message)
            else setOptText(error)
            setTimeout(() => {
                setCountdown(5)
                setOptStatus(status)
                setLoader(false)
            }, 2000);
        } catch (err) {
            alert("Operation failure: Please contact administrator if the issue persists. Error: " + err.message)
        }
    }

    const handleClose = () => {
        setShow(false)
    }

    useEffect(() => {
        let timer
        if (countdown > 0) {
          timer = setInterval(() => {
            setCountdown(prevCountdown => prevCountdown - 1)
          }, 1000)
        } else if (countdown === 0) {
          clearInterval(timer)
          handleClose()
        }
    
        return () => clearInterval(timer)
      }, [countdown])

      useEffect(() => {
        window.scroll(0,0)
      }, [])
    
    return (
        <>
            {/* Hero Section */}
            <section>
                <div className="video-background-holder">
                    <div className="video-background-overlay"></div>
                    <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
                        <source src={homeVideo} type="video/mp4" />
                    </video>
                    <div className="video-background-content container h-100">
                        <div className="d-flex h-100 text-center align-items-center">
                            <div className="w-100 text-white" style={{paddingTop: "70px"}}>
                                <h1 className="display-4 pb-2" data-aos="fade-up" data-aos-duration="800" data-aos-delay="100"><b>One of the leading Direct Sales Agency and Financial Consultancy in UAE</b></h1>
                                <p className="lead" data-aos="fade-up" data-aos-delay="600" data-aos-duration="600">
                                    {/* go to about page */}
                                    <div className="btn-get-started scrollto pointer">
                                        Get Started
                                    </div>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="customBox container">
                <div className="d-flex justify-content-center">
                    <div className="px-4 py-2 bg-white d-flex customBoxLabel">
                        <h4 className="m-0 px-3">🌟</h4>
                        <h6 className="m-0 align-self-center"><b>Popular Services</b></h6>
                        <h4 className="m-0 px-3">🌟</h4>
                    </div>
                </div>
                <div className="customHomeBox row text-center">
                    <div className="col-2 p-4 triggerBox" onClick={() => {setValidation({}); setShow(true); setOptStatus(""); setOptText(""); setFormValue({ ...initialValue, type: "Credit Card" })}}>
                        <img src={creditIcon1} className="img-fluid" width={100} />
                        <h5 className="pt-4 m-0">Credit Card</h5>
                    </div>
                    <div className="col-2 p-4 triggerBox" onClick={() => {setValidation({}); setShow(true); setOptStatus(""); setOptText(""); setFormValue({ ...initialValue, type: "Account Opening" })}}>
                        <img src={accountIcon} className="img-fluid" width={100} />
                        <h5 className="pt-4 m-0">Account Opening</h5>
                    </div>
                    <div className="col-2 p-4 triggerBox" onClick={() => {setValidation({}); setShow(true); setOptStatus(""); setOptText(""); setFormValue({ ...initialValue, type: "Personal Loan" })}}>
                        <img src={personalLoanIcon} className="img-fluid" width={100} />
                        <h5 className="pt-4 m-0">Personal Loan</h5>
                    </div>
                    <div className="col-2 p-4 triggerBox" onClick={() => {setValidation({}); setShow(true); setOptStatus(""); setOptText(""); setFormValue({ ...initialValue, type: "Auto Loan" })}}>
                        <img src={autoLoanIcon} className="img-fluid" width={100} />
                        <h5 className="pt-4 m-0">Auto Loan</h5>
                    </div>
                    <div className="col-2 p-4 triggerBox" onClick={() => {setValidation({}); setShow(true); setOptStatus(""); setOptText(""); setFormValue({ ...initialValue, type: "Mortgage Loan" })}}>
                        <img src={mortgageLoanIcon} className="img-fluid" width={100} />
                        <h5 className="pt-4 m-0">Mortgage Loan</h5>
                    </div>
                    <div className="col-2 p-4 triggerBox" onClick={() => {setValidation({}); setShow(true); setOptStatus(""); setOptText(""); setFormValue({ ...initialValue, type: "SME loan" })}}>
                        <img src={SMELoanIcon} className="img-fluid" width={100} />
                        <h5 className="pt-4 m-0">SME Loan</h5>
                    </div>
                </div>
            </div>
        
            {/* about1 Section */}
            <section id="about1" className="about1 position-relative">
                <div className="container" data-aos="fade-up">
                    <div className="row no-gutters">
                        <div className="content col-xl-7 d-flex align-items-stretch">
                            <div className="content">
                                <h3>Leading Channel Partner of Multiple Banks</h3>
                                <p style={{fontSize: "18px"}}>
                                    <b>We provide one-stop solution for your financial needs. We act as a bridge between bank and customer in making easy application process.</b>
                                </p>
                                <Link to={"/about"} className="about1-btn">
                                    About Us <FontAwesomeIcon className="ps-2" fontSize={10} icon={faChevronRight} />
                                </Link>
                            </div>
                        </div>            
                        
                        <div className=" col-xl-5 d-flex align-items-center">
                            <div className="caro" style={{background: `linear-gradient(rgba(6, 45, 83, 0.8), rgba(3, 38, 73, 0.8)),url(${homeBoxBack})`, backgroundPosition: "center"}}>
                                <Slider {...settings}>
                                    <div className="col-md-12">
                                        <div className="single-box" >
                                            <div className="text">
                                                <h2>RESPONSIBILITY</h2>
                                                <p>We act responsibly. We are aware that our decisions and actions affects businesses. We hold ourselves accountable for the disciplined management.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="single-box">
                                            <div className="text">
                                                <h2>EMPOWERMENT</h2>
                                                <p>Although we seek direction from our leader, we believe that everyone is empowered to make a difference in our workplace. </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="single-box">
                                            <div className="text">
                                                <h2>COMMITMENT</h2>
                                                <p>We are fully committed to achieving success for our clients, our team and ourselves.</p>
                                            </div>0
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="single-box">
                                            <div className="text">
                                                <h2>Team Work</h2>
                                                <p>Our success involves everyone working together for a common goal. We believe in culture of team spirit and collaboration.</p>
                                            </div>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
                    
            {/* about2 Section */}
            <section id="about1" className="about1 wiz position-relative">
                <div className="container" data-aos="fade-up">
                    <div className="row no-gutters content">
                    
                        <div class="section-title aos-init aos-animate" data-aos="fade-right" data-aos-delay="500">
                            <h2>WizHull joins the Fintrek Marketing Group</h2>
                            <img src={logoWizz} className="wizLogo"/>
                            <p style={{fontSize:"18px"}}>
                                Fintrek Marketing is thrilled to announce our official partnership with WizHull, a 
                                renowned mortgage broker. Leveraging WizHull's mortgage expertise with our prowess in 
                                financial marketing, we're set to redefine client experiences. Together, we're bringing 
                                unparalleled service and financial insights to the forefront.
                            </p>
                            <Link to={"https://wizhull.com"} target="_blank" rel="noopener noreferrer" className="about1-btn">
                                Visit now <FontAwesomeIcon className="ps-2" fontSize={10} icon={faChevronRight} />
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        
            {/* Counts Section */}
            <section id="counts" className="counts" style={{background: `linear-gradient(rgba(12, 13, 14, 0.658), rgba(12, 13, 14, 0.644)),url(${homeCount}) fixed center center`, backgroundSize:"cover"}}>
                <div className="container" data-aos="fade-right">
                    <div className="section-title">
                        <h2>Our Firm at a Glance</h2>
                    </div>
                    <div className="row" ref={nodeRef}>
                        <div className="col-lg-3 col-md-6">
                            <div className="count-box" data-aos="fade-right" data-aos-delay="100">
                                <i> <img style={{width: "30px", height: "30px"}} src={smileIcon} alt="" /></i>
                                {
                                    isVisible && 
                                        <CountUp start={0} end={400000} duration={3} />
                                }
                                <p>Happy Clients</p>
                            </div>
                        </div>
                
                        <div className="col-lg-3 col-md-6 mt-5 mt-md-0">
                            <div className="count-box" data-aos="fade-right" data-aos-delay="300">
                                <i> <img src={awardIcon} alt="" /></i>
                                {
                                    isVisible && 
                                        <CountUp start={0} end={15} duration={3} />
                                }
                                <p>Awards</p>
                            </div>
                        </div>
                
                        <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
                            <div className="count-box" data-aos="fade-right" data-aos-delay="500">
                                <i><img style={{width: "40px", height: "40px"}} src={paperIcon} alt="" /></i>
                                {
                                    isVisible && 
                                        <CountUp start={0} end={25} duration={3} />
                                }
                                <p>Years of experience</p>
                            </div>
                        </div>
                
                        <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
                            <div className="count-box" data-aos="fade-right" data-aos-delay="700">
                                <i><img src={teamIcon} alt="" /></i>
                                {
                                    isVisible && 
                                        <CountUp start={0} end={500} duration={3} />
                                }
                                <p>Hard Workers</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        
            {/* Services Section */}
            <section id="services" className="services section-bg">
                <div className="container">
            
                    <div className="section-title"  data-aos="fade-right" data-aos-delay="500">
                    <h2>Services</h2>
                    <p>Fintrek marketing is a premier financial and marketing consultant. Dedicated towards customer services in various channel of financial services.</p>
                    </div>
            
                    <div className="row">
                        <div className="col-md-6">
                            <div className="icon-box" data-aos="fade-up" data-aos-delay="100" style={{background: "linear-gradient(rgba(7, 36, 65, 0.932), rgba(3, 34, 65, 0.904))"}} >
                                <FontAwesomeIcon className="ps-1 position-absolute" color="#3ce5fc" fontSize={40} icon={faCalendarDays} />
                                <h4 style={{color: "#fff"}}>Direct Sales</h4>
                                <p style={{color: "rgb(217, 240, 243)"}}>We specialize in personal loans, auto loans, home loans, and credit cards. In a short time, we've become one of the leading DSAs in the UAE, focusing on customer satisfaction, teamwork, and excellent service to help clients achieve their goals.</p>
                            </div>
                        </div>
                        <div className="col-md-6 mt-2 mt-md-0"> 
                            <div className="icon-box" data-aos="fade-up" data-aos-delay="200" style={{background: "linear-gradient(rgb(233, 244, 248), rgb(233, 244, 248))"}}>
                                <FontAwesomeIcon className="ps-1 position-absolute" color="#12a0cc" fontSize={40} icon={faSuitcase} />
                                <h4 style={{color: "#0f2b52"}}>Loans</h4>
                                <p style={{color: "#0f2b52"}}>We at Fintrek help you with all your financial needs, whether it be for educational purposes, or small business, or just because you need extra money for your daily expenses. <span>We can help you by providing mortgage loans, auto loans, and more.</span></p>
                            </div>
                        </div>
                        <div className="col-md-6 mt-2 mt-md-0">
                            <div className="icon-box" data-aos="fade-up" data-aos-delay="300" style={{background: "linear-gradient(rgb(233, 244, 248), rgb(233, 244, 248))"}}>
                                <FontAwesomeIcon className="ps-1 position-absolute" color="#12a0cc" fontSize={40} icon={faCreditCard} />
                                <i className="bi bi-card-heading" style={{color: "#12a0cc"}}></i>        
                                <h4 style={{color: "#0f2b52"}}>Credit cards</h4>
                                <p style={{color: "#0f2b52"}}>We understand your need for an easy-to-use credit card. Apply for a free lifetime credit card today with no annual charges. Our simple application process makes spending more rewarding, letting you swipe and tap with ease for a hassle-free financial experience.</p> 
                            </div>
                        </div>
                        <div className="col-md-6 mt-2 mt-md-0 ">
                            <div className="icon-box" data-aos="fade-up" data-aos-delay="400" style={{background: "linear-gradient(rgba(7, 36, 65, 0.932), rgba(3, 34, 65, 0.904))"}}>
                                <FontAwesomeIcon className="ps-1 position-absolute" color="#3ce5fc" fontSize={40} icon={faStackOverflow} />
                                <h4 style={{color: "#fff"}}>Financing </h4>
                                <p style={{color: "rgb(217, 240, 243)"}}>Finance is the centre point of the company we offer a wide range of services and solutions for your financial needs. Our portfolio for financing includes loans, credit cards, insurance and SME loans. <span>We’ll work hard on being flexible so you can find the best solution for your needs.</span> </p> 
                            </div>
                        </div>
                        <div className="col-md-6 mt-2 mt-md-0">
                            <div className="icon-box" data-aos="fade-up" data-aos-delay="500" style={{background: "linear-gradient(rgba(7, 36, 65, 0.932), rgba(3, 34, 65, 0.904))"}}>
                                <FontAwesomeIcon className="ps-1 position-absolute" color="#3ce5fc" fontSize={40} icon={faRectangleList} />
                                <h4 style={{color: "#fff"}}>Insurance</h4>
                                <p style={{color: "rgb(217, 240, 243)"}}>The need for insurance is ever-present. Whether it's for your car, home, or yourself, you want to be prepared. Life's uncertainties demand reliable protection. Let us help you find an affordable plan that covers your needs and provides the best value for peace of mind.</p>
                            </div>
                        </div>
                        <div className="col-md-6 mt-2 mt-md-0">
                            <div className="icon-box" data-aos="fade-up" data-aos-delay="600" style={{background: "linear-gradient(rgb(233, 244, 248), rgb(233, 244, 248))"}}>
                                <FontAwesomeIcon className="ps-1 position-absolute" color="#12a0cc" fontSize={40} icon={faPhoneVolume} />
                                <h4 style={{color: "#0f2b52"}}>Telesales</h4>
                                <p style={{color: "#0f2b52"}}> For banks in UAE, we provide personal loans and credit cards through telesales. By leveraging sales tools and resources, we identify new sales prospects and nurture existing relationships with prospects.<span> We engage with our customers and are equipped to obtain more business.</span></p>
                            </div>
                        </div>
                        <div className="col-md-6 mt-2 mt-md-0">
                            <div className="icon-box" data-aos="fade-up" data-aos-delay="600" style={{background: "linear-gradient(rgb(233, 244, 248), rgb(233, 244, 248))"}}>
                                <FontAwesomeIcon className="ps-1 position-absolute" color="#12a0cc" fontSize={40} icon={faFileMedical} />
                                <h4 style={{color: "#0f2b52"}}>Debt Collection <span>and Recovery</span></h4>
                                <p style={{color: "#0f2b52"}}>We have a team of talented individuals ready to handle any concerns or disputes you might be experiencing regarding your unpaid debts. Through communication, we strive to find a suitable solution<span> for unresolved accounts</span>.</p>
                            </div>
                        </div>
                        <div className="col-md-6 mt-2 mt-md-0">
                            <div className="icon-box" data-aos="fade-up" data-aos-delay="600" style={{background: "linear-gradient(rgb(233, 244, 248), rgb(233, 244, 248))"}}>
                                <FontAwesomeIcon className="ps-1 position-absolute" color="#12a0cc" fontSize={40} icon={faFileMedical} />
                                <h4 style={{color: "#0f2b52"}}>Risk Advisory <span>Services</span></h4>
                                <p style={{color: "#0f2b52"}}>We have a team of seasoned professionals equipped to address any concerns or complexities in your risk management processes. Through meticulous analysis and strategic planning, we endeavor to devise effective solutions <span> for managing your organization's risk profile</span>.</p>
                            </div>
                        </div>
                        <div className="col-md-6 mt-2 mt-md-0">
                            <div className="icon-box" data-aos="fade-up" data-aos-delay="500" style={{background: "linear-gradient(rgba(7, 36, 65, 0.932), rgba(3, 34, 65, 0.904))"}}>
                                <FontAwesomeIcon className="ps-1 position-absolute" color="#3ce5fc" fontSize={40} icon={faChartColumn} />
                                <h4 style={{color: "#fff"}}>Online Marketing</h4>
                                <p style={{color: "rgb(217, 240, 243)"}}>Fintrek expertise provide you with a detailed marketing plan that ensures your company is always relevant in today's market. We have experience working with firms of all sizes, so let us put our expertise to work for you!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="career-bg" class="career-front">
                <div class="career-bg" style={{background: `linear-gradient(rgba(12, 13, 14, 0.8), rgba(12, 13, 14, 0.8)),url(${homeCareer}) fixed center center`}}>
                    <div class="container">
                        <div class="row content">
                            <div class="service-head d-flex align-items-stretch">
                                <h1 data-aos="fade-right">Careers</h1>
                            </div>
                            <div class="col-lg-6 pt-0">
                                <div class="career-head" data-aos="fade-right">
                                    <p>Fintrek Marketing is an institute to modify staff into professional bankers. We are recognized for our training and quality of work that makes us award-winning direct sales agency in UAE.  We recruit for  variety of roles across our teams. If you are looking for your next career move in banking related sector, then please take some time to explore the opportunities here at Fintrek Marketing.</p>
                                </div>
                            </div>
                            <div class="col-lg-6 p-4 pt-lg-0 service-head" data-aos="fade-left">
                                <ul>
                                    <li>
                                        <FontAwesomeIcon className="pe-3" color="#3ce5fc" fontSize={15} icon={faChevronRight} />
                                        We offer competitive salaries and flexible working hours.
                                    </li>
                                    <li>
                                        <FontAwesomeIcon className="pe-3" color="#3ce5fc" fontSize={15} icon={faChevronRight} />
                                        Many career opportunity available from entry level to executive level jobs.
                                    </li>
                                    <li>
                                        <FontAwesomeIcon className="pe-3" color="#3ce5fc" fontSize={15} icon={faChevronRight} />
                                        Provide you the necessary training before you enter into the field.
                                    </li>
                                </ul>
                                <Link to={"/career"} className="btn-learn-more">
                                    Explore Jobs
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="clients" class="clients">
                <div class="container" data-aos="zoom-in">
                    <div class="heading pt-0 pb-5" style={{textAlign: "center", color: "#0f2b52"}}>
                        <h1 class="sharceus"><b> Our Partners</b></h1>
                    </div>
                    
                    <div className="row homePartner">
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-5">
                            <img src={eisLogo} height={"100px"} alt="" />
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-5">
                            <img src={nbdLogo} height={"100px"} alt="" />
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-5">
                            <img src={mashreqLogo} height={"100px"} alt="" />
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-5">
                            <img src={gemsLogo} height={"100px"} alt="" />
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-5">
                            <img src={sibLogo} height={"100px"} alt="" />
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-5">
                            <img src={cbdLogo} height={"100px"} alt="" />
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-5">
                            <img src={dibLogo} height={"100px"} alt="" />
                        </div>
                    </div>
                </div>
            </section>
            
            <Modal
                show={show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={handleClose}
                >
                {
                    loader &&
                    <Oval
                        height={200}
                        width={200}
                        color="#0d254d"
                        wrapperStyle={{}}
                        wrapperClass="customClass"
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#9CC3D5FF"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                }
                <Modal.Body className="py-4 my-2 px-4">
                    {
                        optStatus == "" ?
                        <>
                            <h4 className="pb-4 m-0">Complete form below to apply for <b>{type}</b></h4>
                            <p className="m-0 pb-4">
                                <div className="row">
                                    <div className="col-4">
                                        <div className="pb-3">Full Name:</div>
                                        <InputGroup className="mb-0">
                                            <Form.Control
                                                aria-label="Default"
                                                aria-describedby="inputGroup-sizing-default"
                                                placeholder="Enter your name here"
                                                className={`py-3 ${!isEmpty(validation?.name) && "errorInputField"}`}
                                                name="name"
                                                value={name}
                                                onChange={handleChange}
                                            />
                                        </InputGroup>
                                        {
                                            !isEmpty(validation?.name) && 
                                                <div className="errorText">* This field is required</div>
                                        }
                                    </div>
                                    <div className="col-3">
                                        <div className="pb-3">Date of Birth:</div>
                                        <InputGroup className="customDatePicker w-100">
                                            <DatePicker
                                                className={`w-100 ${!isEmpty(validation?.dob) && "errorInputField"}`}
                                                selected={dob}
                                                onChange={handleDateChange}
                                                value={dob}
                                                placeholderText="Select Date"
                                            />
                                        </InputGroup>
                                        {
                                            !isEmpty(validation?.dob) && 
                                                <div className="errorText">* This field is required</div>
                                        }
                                    </div>
                                    <div className="col-5">
                                        <div className="pb-3">Gender:</div>
                                        <div className="d-flex">
                                            <InputGroup className={`pe-3 bg-white toggle ${!isEmpty(validation?.gender) && "errorCheckboxField"}`} onClick={() => {handleGender("male")}}>
                                                <InputGroup.Radio 
                                                    aria-label="Radio button for following text input"
                                                    value="male"
                                                    checked={gender == "male"} />
                                                <Form.Control 
                                                    style={{padding: "15px 0", textAlign: "center", borderLeft: "none", pointerEvents: "none"}} 
                                                    aria-label="Text input with radio button" 
                                                    className="bg-white" 
                                                    placeholder="Male" 
                                                    disabled />
                                            </InputGroup>
                                            <InputGroup className={`toggle ${!isEmpty(validation?.gender) && "errorCheckboxField"}`} onClick={() => {handleGender("female")}}>
                                                <InputGroup.Radio 
                                                    aria-label="Radio button for following text input" 
                                                    value="female"
                                                    checked={gender == "female"} />
                                                <Form.Control 
                                                    style={{padding: "15px 0", textAlign: "center", borderLeft: "none", pointerEvents: "none"}} 
                                                    aria-label="Text input with radio button" 
                                                    className="bg-white" 
                                                    placeholder="Female" 
                                                    disabled />
                                            </InputGroup>
                                        </div>
                                        {
                                            !isEmpty(validation?.gender) && 
                                                <div className="errorText">* This field is required</div>
                                        }
                                    </div>
                                    <div className="col-6 pt-4">
                                        <div className="pb-3">Email:</div>
                                        <InputGroup>
                                            <Form.Control
                                                aria-label="Default"
                                                aria-describedby="inputGroup-sizing-default"
                                                placeholder="abc@example.com"
                                                className={`py-3 ${!isEmpty(validation?.email) && "errorInputField"}`}
                                                name="email"
                                                value={email}
                                                onChange={handleChange}
                                            />
                                        </InputGroup>
                                        {
                                            !isEmpty(validation?.email) && 
                                                <div className="errorText">* This field is required</div>
                                        }
                                    </div>
                                    <div className="col-6 pt-4">
                                        <div className="pb-3">Mobile Number:</div>
                                        <InputGroup>
                                            <Form.Control
                                                aria-label="Default"
                                                aria-describedby="inputGroup-sizing-default"
                                                placeholder="050 123 4567"
                                                className={`py-3 ${!isEmpty(validation?.phone) && "errorInputField"}`}
                                                type="number"
                                                name="phone"
                                                value={phone}
                                                onChange={handleChange}
                                            />
                                        </InputGroup>
                                        {
                                            !isEmpty(validation?.phone) && 
                                                <div className="errorText">* This field is required</div>
                                        }
                                    </div>
                                    <div className="col-6 pt-4">
                                        <div className="pb-3">Nationality:</div>
                                        <InputGroup>
                                            <Select
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        padding: "10px 0"
                                                    }),
                                                }}
                                                className={`w-100 ${!isEmpty(validation?.nationality) && "errorSelectField"}`}
                                                aria-labelledby="aria-label"
                                                inputId="aria-example-input"
                                                onChange={(e) => {handleSelectChange(e, "nationality")}}
                                                options={nationalities}
                                            />
                                        </InputGroup>
                                        {
                                            !isEmpty(validation?.nationality) && 
                                                <div className="errorText">* This field is required</div>
                                        }
                                    </div>
                                    <div className="col-6 pt-4">
                                        <div className="pb-3">Emirates:</div>
                                        <InputGroup>
                                            <Select
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        padding: "10px 0"
                                                    }),
                                                }}
                                                className={`w-100 ${!isEmpty(validation?.emirates) && "errorSelectField"}`}
                                                aria-labelledby="aria-label"
                                                inputId="aria-example-input"
                                                onChange={(e) => {handleSelectChange(e, "emirates")}}
                                                options={uaeMainCities}
                                            />
                                        </InputGroup>
                                        {
                                            !isEmpty(validation?.emirates) && 
                                                <div className="errorText">* This field is required</div>
                                        }
                                    </div>
                                    <div className="col-6 pt-4">
                                        <div className="pb-3">Company Name:</div>
                                        <InputGroup>
                                            <Form.Control
                                                aria-label="Default"
                                                aria-describedby="inputGroup-sizing-default"
                                                placeholder="Example llc"
                                                className={`py-3 ${!isEmpty(validation?.company) && "errorInputField"}`}
                                                name="company"
                                                value={company}
                                                onChange={handleChange}
                                            />
                                        </InputGroup>
                                        {
                                            !isEmpty(validation?.company) && 
                                                <div className="errorText">* This field is required</div>
                                        }
                                    </div>
                                    <div className="col-6 pt-4">
                                        <div className="pb-3">Salary:</div>
                                        <InputGroup>
                                            <Form.Control
                                                aria-label="Default"
                                                aria-describedby="inputGroup-sizing-default"
                                                placeholder="5000"
                                                className={`py-3 ${!isEmpty(validation?.salary) && "errorInputField"}`}
                                                name="salary"
                                                type="number"
                                                value={salary}
                                                onChange={handleChange}
                                            />
                                        </InputGroup>
                                        {
                                            !isEmpty(validation?.salary) && 
                                                <div className="errorText">* This field is required</div>
                                        }
                                    </div>
                                </div>
                            </p>
                            <Button onClick={handleSubmit}>Apply Now</Button>
                        </>
                        : optStatus == "success" ?
                        <>
                            <h5 className="pb-4 m-0 text-center">Your <b>{type}</b> application has been successfully submitted. Our agent will be in touch shortly</h5>
                            <SuccessLottie />
                            <h7 className="d-block text-center w-100 pt-4"><i>Returning to page in {countdown}</i></h7>
                        </>
                        :
                        <>
                            <h4 className="pb-4 m-0">Submission failed. Something must have gone wrong, please contact info@fintrekmarketing.com for application submission.</h4>
                            <h7 className="d-block text-center w-100 pt-4"><i>Returning to page in {countdown}</i></h7>
                        </>
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Home   