import React, { useState } from "react"
import finance from "../assets/imgs/contactUs/finance.jpg"
import contact1 from "../assets/imgs/contactUs/contact1.jpg"
import co3 from "../assets/imgs/contactUs/co3.jpg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLocationDot, faClock, faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons"
import { isEmpty } from "../config/funcs"
import { applyContact } from "../actions/common"
import { contactValidation } from "../component/Validation"
import { Oval } from "react-loader-spinner"
import SuccessLottie from "../component/successLottie"
import { useEffect } from "react"
import { Modal } from "react-bootstrap"

const initialValue = {
    name: '',
    email: '',
    phone: '',
    message: ''
}

const Contact = () => {

    const [show, setShow] = useState(false)
    const [formValue, setFormvalue] = useState(initialValue)
    const [focus, setFocus] = useState("")
    const [loader, setLoader] = useState(false)
    const [optText, setOptText] = useState("")
    const [optStatus, setOptStatus] = useState("")
    const [countdown, setCountdown] = useState(5);
    const { name, email, phone, message } = formValue

    const onFocus = (name) => { setFocus(name) }
    const offFocus = () => { setFocus("") }

    const handleInput = (e) => {
        const { value, name } = e.target

        setFormvalue({
            ...formValue, [name]: value
        })
    }

    const onSubmit = async (e) => {
        try {
            e.preventDefault()

            let formData = formValue

            let _error = contactValidation(formData)
            if(Object.keys(_error).length > 0 && !isEmpty(_error)) return

            setLoader(true)

            let { status, message, error } = await applyContact(formData)
            if(status == "success") setOptText(message)
            else setOptText(error)
            setTimeout(() => {
                setCountdown(5)
                setOptStatus(status)
                setLoader(false)
                setShow(true)
            }, 2000)
        } catch (err) {
            alert("Operation failure: Please contact administrator if the issue persists. Error: " + err.message)
        }
    }

    const handleClose = () => {
        setShow(false)
    }

    useEffect(() => {
        let timer
        if (countdown > 0) {
          timer = setInterval(() => {
            setCountdown(prevCountdown => prevCountdown - 1)
          }, 1000)
        } else if (countdown === 0) {
          clearInterval(timer)
          handleClose()
        }
    
        return () => clearInterval(timer)
      }, [countdown])

    useEffect(() => {
        window.scroll(0,0)
    }, [])

    return (
    <>
        <section id="breadcrumbs" className="d-flex align-items-center" style={{backgroundImage: `url(${finance})`,backgroundRepeat:"none" ,paddingBottom:" 100px", paddingTop: "100px", marginTop:"70px"}}>
            <div className="container position-relative" data-aos="fade-up" data-aos-delay="500">
                <h1 className="pt-3">Get In Touch</h1>
                <div className="text-center">
                    <a href="#contact" className="btn-get-started scrollto">Contact Us</a>
                </div>
            </div>
        </section>
        <section>
            <div className="section-title">
                <h2>Fintrek Group of Companies</h2>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <h4 className="text-center" style={{color: "#0f2b52"}}><strong>Fintrek Marketing Consultancy</strong></h4>
                        <hr />
                        <div className="profile-card-4 text-center"><img src={co3} />
                            <div className="profile-content">
                                <div className="profile-description">In Marketing consultancy we work with consumer financial services organizations including life insurance and retirement companies, property and casualty insurers, credit card issuers, mortgage bankers, asset managers, investment advisors and financial planners. We provide advice on all aspects of their business to achieve growth objectives.</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 pt-4 pt-lg-0">
                        <h4 className="text-center" style={{color:" #0f2b52"}}><strong>Fintrek Marketing</strong></h4>
                        <hr />
                        <div className="profile-card-4 text-center"><img src={contact1} className="img img-responsive" />
                            <div className="profile-content">
                                <div className="profile-description">Fintrek Marketing helps clients to formulate marketing strategies and plans, align and organizations with overall objectives, identify opportunities, select appropriate tactics and implement them effectively. And help clients take a holistic look at how they market their products, using proven techniques to achieve both higher performance and efficiency.</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 pt-4 pt-lg-0">
                        <h4 className="text-center" style={{color:"#0f2b52"}}><strong>Fintrek Rescheduling Services</strong></h4>
                        <hr />
                        <div className="profile-card-4 text-center"><img src={contact1} className="img img-responsive" />
                            <div className="profile-content">
                                <div className="profile-description">In Rescheduling services we ensure that the client receive the due attention and care that their situation merits, is constantly in touch by providing feedback of development and milestone on the progress of their cases. We understand the importance of time and money and work hard to ensure that clients get the best out of our services.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="contact" className="contact">
            <div className="container-fluid">
                <div className="section-title">
                    <h2>Get In Touch With <span>Us</span></h2>
                    <p>Do not hesitate to contact us. We are always happy to help you with any financial services you need.
                    </p>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-xl-10">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="con-box row justify-content-center pt-2">
                                <div className="col-md-5 col-5 info d-flex flex-column align-items-stretch">
                                    <FontAwesomeIcon
                                        className="pb-4" 
                                        fontSize={"2.5em"}
                                        icon={faLocationDot} />
                                    <b className="pb-2">Head Office Address</b>
                                    <p>Office No. 1806,<br /> Silver Tower <br /> Business Bay, Dubai.</p>
                                </div>
                                <div className="col-md-5 col-5 info d-flex flex-column align-items-stretch">
                                    <FontAwesomeIcon
                                        className="pb-4" 
                                        fontSize={"2.5em"}
                                        icon={faClock} />
                                    <b className="pb-2">Working Hours</b>
                                    <p>Mon - Sat: 8.30AM to 6PM<br />Sunday: Closed</p>
                                </div>
                                <div className="col-md-5 col-5 info d-flex flex-column align-items-stretch toggle" onClick={() => window.open("mailto:about@fintrekmarketing.com")} style={{padding: "30px 3px"}}>
                                    <FontAwesomeIcon
                                        className="pb-4" 
                                        fontSize={"2.5em"}
                                        icon={faEnvelope} />
                                    <b className="pb-2">Email Us</b>
                                    <p>about@fintrekmarketing.com</p>
                                </div>
                                <div className="col-md-5 col-5 info d-flex flex-column align-items-stretch toggle" onClick={() => window.open("tel:+97144358112")} style={{padding: "30px 3px"}}>
                                    <FontAwesomeIcon
                                        className="pb-4" 
                                        fontSize={"2.5em"}
                                        icon={faPhone} />
                                    <b className="pb-2">Call Us</b>
                                    <p>+971 4 435 8112</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="contact-form">
                                {
                                    loader &&
                                    <Oval
                                        height={200}
                                        width={200}
                                        color="#0d254d"
                                        wrapperStyle={{}}
                                        wrapperClass="customClass"
                                        visible={true}
                                        ariaLabel='oval-loading'
                                        secondaryColor="#9CC3D5FF"
                                        strokeWidth={2}
                                        strokeWidthSecondary={2}
                                    />
                                }
                                <form>
                                    <h3 className="title">Contact us</h3>
                                    <div className={`input-container ${(!isEmpty(name) || focus == "name") && "focus" }`}>
                                        <input type="text" name="name" className="input" onChange={handleInput} value={name} onBlur={offFocus} onFocus={() => { onFocus("name") }} required />
                                        <label for="name">Name</label>
                                        <span>Name</span>
                                    </div>
                                    <div className={`input-container ${(!isEmpty(email) || focus == "email") && "focus" }`}>
                                        <input type="email" name="email" className="input" onChange={handleInput} value={email} onBlur={offFocus} onFocus={() => { onFocus("email") }} required />
                                        <label for="email">Email</label>
                                        <span>Email</span>
                                    </div>
                                    <div className={`input-container ${(!isEmpty(phone) || focus == "phone") && "focus" }`}>
                                        <input type="tel" name="phone" className="input" onChange={handleInput} value={phone} onBlur={offFocus} onFocus={() => { onFocus("phone") }} required />
                                        <label for="phone">Phone</label>
                                        <span>Phone</span>
                                    </div>
                                    <div className={`input-container ${(!isEmpty(message) || focus == "message") && "focus" } textarea`}>
                                        <textarea name="message" className="input" onChange={handleInput} value={message} onBlur={offFocus} onFocus={() => { onFocus("message") }} required />
                                        <label for="message">Message</label>
                                        <span>Message</span>
                                    </div>
                                    <input className="btn btn-outline-light" type="submit" name="submit" value="Submit" onClick={onSubmit}></input>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-title pt-5">
                <h2>Fintrek's other branches in UAE</h2>
            </div>
            <div className="con-box row justify-content-center">
                <div className="col-lg-2 col-5 info1 d-flex flex-column align-items-stretch">
                    <i className="bx bx-map"></i>
                    <h4>Address</h4>
                    <p>Office No.2202 & 2203,<br /> Silver TowerBusiness Bay, Dubai, UAE.</p>
                </div>
                <div className="col-lg-2 col-5 info1 d-flex flex-column align-items-stretch">
                    <i className="bx bx-map"></i>
                    <h4>Address</h4>
                    <p>Office No.2003, silver Tower<br />Business Bay, Dubai,UAE.</p>
                </div>
                <div className="col-lg-2 col-5 info1 d-flex flex-column align-items-stretch">
                    <i className="bx bx-map"></i>
                    <h4>Address</h4>
                    <p>Ontario Tower <br /> Business Bay, Dubai, UAE.</p>
                </div>
                <div className="col-lg-2 col-5 info1 d-flex flex-column align-items-stretch">
                    <i className="bx bx-map"></i>
                    <h4>Address</h4>
                    <p>Office No. 2301, ADCP <br />Tower-A, Dnata Building, <br />Abu Dhabi, UAE.</p>
                </div>
            </div>
            <div> <iframe style={{border:"0", width: "100%" ,height: "270px"}} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.5488660599553!2d55.262392014484114!3d25.18470643838862!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e67ef44b087e5%3A0x832256e8ebb6c274!2sFintrek%20Marketing!5e0!3m2!1sen!2sae!4v1635685040018!5m2!1sen!2sae"></iframe></div>
        </section>

        <Modal
            show={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={handleClose}
            >
            <Modal.Body className="py-4 my-2 px-4">
                {
                    optStatus == "success" ?
                    <>
                        <h5 className="pb-4 m-0 text-center">Thank you for reaching out to us. Your inquiry has been successfully received. Our team will be in touch with you shortly.</h5>
                        <SuccessLottie />
                        <h7 className="d-block text-center w-100 pt-4"><i>Returning to page in {countdown}</i></h7>
                    </>
                    :
                    <>
                        <h4 className="pb-4 m-0">Submission failed. Something must have gone wrong, please contact info@fintrekmarketing.com for application submission.</h4>
                        <h7 className="d-block text-center w-100 pt-4"><i>Returning to page in {countdown}</i></h7>
                    </>
                }
            </Modal.Body>
        </Modal>
    </>
    )
}

export default Contact