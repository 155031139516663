import React from "react"

const Consulation = () => {
  return (
    <>
        <section id="contact" className="contact">
            <div className="container-fluid pt-5">
                <div className="section-title pt-4">
                    <h2>Financial Consultation</h2>
                    <h4 style={{color: "#0f2b52;"}}>Fill the details of your financial inquiry, and we will assign a specialized agent get back to you shortly.</h4>
                </div>
                <div className="row justify-content-center">
                    <div className="col-xl-6">
                        <div style={{ background: "#0f2b52" }}>
                            <form action="" method="post" autocomplete="off">
                                <h3 className="title">Contact us</h3>
                                <div className="input-container">
                                    <input type="text" name="name" className="input" id="name" required />
                                    <label for="name">Name</label>
                                    <span>Name</span>
                                </div>
                                <div className="input-container">
                                    <input type="email" name="email" className="input" id="email" />
                                    <label for="email">Email</label>
                                    <span>Email</span>
                                </div>
                                <div className="input-container">
                                    <input type="tel" name="phone" className="input" id="phone" />
                                    <label for="phone">Phone</label>
                                    <span>Phone</span>
                                </div>
                                <input type="date" name="date" className="input" id="date" required />
                                <div className="input-container">
                                    <select name="subject" className="input" id="subject" required>
                                        <option value="">Inquiry for</option>
                                        <option value="creditcard">Credit card</option>
                                        <option value="Personal loan">Personal Loans</option>
                                        <option value="Home loan">Home Loans</option>
                                        <option value="SME loan">SME Loans</option>
                                        <option value="Auto loan">Auto Loans</option>
                                        <option value="insurance">Insurance</option>
                                        <option value="marketing">Marketing</option>
                                    </select>
                                </div>
                                <div className="input-container textarea">
                                    <textarea name="message" className="input" id="message" required></textarea>
                                    <label for="message">Message</label>
                                    <span>Message</span>
                                </div>
                                <input className="btn btn-outline-light" type="submit" name="submit" value="Submit"></input>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
)}

export default Consulation